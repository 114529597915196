import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringNodeKind } from './types';

export const meteringNodeKindApi = createApi({
  reducerPath: 'meteringNodeKindApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-node-kinds`,
  }),
  endpoints: builder => ({
    getMeteringNodeKind: builder.query<MeteringNodeKind[], any>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetMeteringNodeKindQuery } = meteringNodeKindApi;
