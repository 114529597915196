import { extendTheme as extendJoyTheme } from '@mui/joy/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    danger: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    danger: PaletteOptions['primary'];
  }
  interface PaletteColor {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }
}

const palette = {
  danger: {
    50: '#FEF6F6',
    100: '#FCE4E4',
    200: '#F7C5C5',
    300: '#F09898',
    400: '#E47474',
    500: '#C41C1C',
    600: '#A51818',
    700: '#7D1212',
    800: '#430A0A',
    900: '#240505',
  },
  neutral: {
    50: '#FBFCFE',
    100: '#F6F7FA',
    200: '#ECECF1',
    300: '#D4D6DD',
    400: '#9499AA',
    500: '#616778',
    600: '#4B5264',
    700: '#383E50',
    800: '#23293A',
    900: '#27272B',
  },
  primary: {
    50: '#EDE7FF',
    100: '#BBA9FB',
    200: '#A58EF9',
    300: '#987DF9',
    400: '#5A30F5',
    500: '#4B1DF4',
    600: '#3B0AEE',
    700: '#2A00C2',
    800: '#150A44',
    900: '#0D0523',
  },
  success: {
    50: '#F6FEF6',
    100: '#E3FBE3',
    200: '#C7F7C7',
    300: '#A1E8A1',
    400: '#51BC51',
    500: '#1F7A1F',
    600: '#136C13',
    700: '#0A470A',
    800: '#042F04',
    900: '#021D02',
  },
  warning: {
    50: '#FEFAF6',
    100: '#FDF0E1',
    200: '#FCE1C2',
    300: '#F3C896',
    400: '#EA9A3E',
    500: '#9A5B13',
    600: '#72430D',
    700: '#492B08',
    800: '#2E1B05',
    900: '#1D1002',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
};

export const materialTheme = extendTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  colorSchemes: {
    light: { palette },
  },
});

const listboxStyles = {
  listbox: {
    zIndex: 10000,
  },
};

const inputFonSizeStyles = {
  root: {
    fontSize: '14px',
  },
};

const rewrittenInputStyles = {
  styleOverrides: {
    ...inputFonSizeStyles,
  },
};

const rewrittenSelectStyles = {
  styleOverrides: {
    ...inputFonSizeStyles,
    ...listboxStyles,
  },
};

export const joyTheme = extendJoyTheme({
  components: {
    JoyInput: rewrittenInputStyles,
    JoyTextarea: rewrittenInputStyles,
    JoySelect: rewrittenSelectStyles,
    JoyAutocomplete: rewrittenSelectStyles,
    JoyFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
        },
      },
    },
    JoySkeleton: {
      styleOverrides: {
        root: {
          '&::before': {
            background: palette.neutral[200],
          },
        },
      },
    },
  },
  colorSchemes: {
    light: { palette },
  },
});
