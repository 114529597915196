import useUi from '@/hooks/useUi';

import NavCategory from '../Nav/components/NavCategory';
import navigationConfig from './navigationConfig';
import { Container } from './styled';

const AdminPanel = () => {
  const [minimized] = useUi();
  return (
    <Container minimized={!!minimized}>
      <NavCategory {...navigationConfig} />
    </Container>
  );
};

export default AdminPanel;
