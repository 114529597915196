import useUi from '@/hooks/useUi';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import NavCategory from './components/NavCategory';
import navigationConfig from './navigationConfig';

const Nav = () => {
  const [minimized] = useUi();
  return (
    <Stack gap={2} alignItems='flex-start'>
      {navigationConfig.map(({ type, ...category }, idx) => {
        if (type === 'nav' && 'items' in category) {
          return <NavCategory key={idx} {...category} />;
        }

        if (type === 'divider') {
          if (
            typeof minimized === 'boolean' &&
            'show' in category &&
            !category.show(minimized)
          ) {
            return null;
          }
          return (
            <Divider
              key={idx}
              flexItem
              orientation='horizontal'
              sx={{
                borderColor: theme => theme.palette.neutral['600'],
              }}
            />
          );
        }
      })}
    </Stack>
  );
};

export default Nav;
