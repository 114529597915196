import { createContext, useState } from 'react';

import { Notification, NotificationType } from './Notification';

type NotificationPushArgs = {
  message: string;
  title?: string;
  type: NotificationType;
  options?: Partial<{
    duration: number;
  }>;
};

type NotificationContextType = {
  push: (args: NotificationPushArgs) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  push: () => {},
});

type NotificationStore = {
  message: string;
  title?: string;
  type: NotificationType;
  duration?: number;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotification] = useState<NotificationStore[]>([]);

  const push = ({ message, title, type, options }: NotificationPushArgs) => {
    setNotification(prev => {
      return [
        ...prev,
        {
          message,
          title,
          type,
          duration: options?.duration,
        },
      ];
    });
  };

  return (
    <NotificationContext.Provider value={{ push }}>
      {children}
      {notifications.map((nt, key) => (
        <Notification
          key={key}
          message={nt.message}
          dismissTitle={nt.title}
          type={nt.type}
          autoHideDuration={nt.duration}
          show={true}
        />
      ))}
    </NotificationContext.Provider>
  );
};
