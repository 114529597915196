import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasDistributionStationCategory } from './type';

export const gasDistributionStationCategoryApi = createApi({
  reducerPath: 'gdStationCategoryApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getGasDistributionStationCategories: builder.query<
      GasDistributionStationCategory[],
      any
    >({
      query: () => ({
        url: '/gas-distribution-station-categories',
      }),
    }),
  }),
});

export const { useGetGasDistributionStationCategoriesQuery } =
  gasDistributionStationCategoryApi;
