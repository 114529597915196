import { Employee } from '@/features/employees/types';

export interface Role {
  id: string;
  parent: Pick<Role, 'id' | 'name'> | null;
  name: string;
  description: string;
  permissions: Permission[];
}

export type RoleInput = {
  id?: string;
  parentId: string;
  name: string;
  description: string;
  rules: any;
  users: Employee[];
};

export type RoleCreatePayload = {
  organizationId: string;
  parentId: string;
  name: string;
  description: string;
  permissionIds: string[];
};

export type RoleUpdatePayload = {
  id: string;
  organizationId: string;
  name: string;
  description: string;
  permissionIds: string[];
};

export type AssignRoleRequestPayload = {
  id: string;
  employeeIds: string[];
};

export enum RoleType {
  Administrator = 'administrator',
  Manager = 'manager',
  User = 'user',
  Custom = 'custom',
}

export enum RootRoleId {
  Administrator = '832affe9-42a0-488f-8c9a-577de4ed21e2',
  Manager = 'bbd2d79e-ca1b-4f59-b3d8-63e88bdd4ea4',
  User = 'ebd2792e-223b-4788-ac28-08bfa8848306',
}

export interface Permission {
  id: string;
  name: string;
  description: string | null;
}

export type RoleFilters = {
  organizationId: string;
  parentId?: string | null;
  name?: string | null;
  description?: string | null;
  permissionIds: string[];
};
