import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringDevice, MeteringDeviceQueryParams } from './types';

export const meteringDevicesApi = createApi({
  reducerPath: 'meteringDeviceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-devices`,
  }),
  tagTypes: ['MeteringDevice', 'MeteringDevices'],
  endpoints: builder => ({
    getMeteringDevices: builder.query<
      MeteringDevice[],
      MeteringDeviceQueryParams
    >({
      query: ({
        meteringPointId = null,
        commercialMeteringPointId = null,
        gasMeteringNodeId = null,
      }) => ({
        url: '/query',
        method: 'POST',
        data: {
          meteringPointId,
          commercialMeteringPointId,
          gasMeteringNodeId,
        },
      }),
      providesTags: ['MeteringDevices', 'MeteringDevice'],
    }),
  }),
});

export const { useGetMeteringDevicesQuery } = meteringDevicesApi;
