import { createApi } from '@reduxjs/toolkit/query/react';

import { GasDistributionStationType } from '@/features/gasDistributionStationType/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const gasDistributionStationTypeApi = createApi({
  reducerPath: 'gdStationTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getGasDistributionStationTypes: builder.query<
      GasDistributionStationType[],
      any
    >({
      query: () => ({
        url: '/gas-distribution-station-types',
      }),
    }),
  }),
});

export const { useGetGasDistributionStationTypesQuery } =
  gasDistributionStationTypeApi;
