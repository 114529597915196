import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { NodeSealLocations } from './types';

export const nodeSealLocationApi = createApi({
  reducerPath: 'nodeSealLocationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/seal-locations`,
  }),
  endpoints: builder => ({
    getNodeSealLocation: builder.query<NodeSealLocations[], void>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetNodeSealLocationQuery } = nodeSealLocationApi;
