import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { SeasonType } from './types';

export const seasonTypesApi = createApi({
  reducerPath: 'seasonTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/season-types`,
  }),
  endpoints: builder => ({
    getSeasonTypes: builder.query<SeasonType[], any>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetSeasonTypesQuery } = seasonTypesApi;
