import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  MeteringPoint,
  MeteringPointFilters,
  MeteringPointRequestBody,
} from './types';

export const meteringPointApi = createApi({
  reducerPath: 'meteringPointApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  tagTypes: ['MeteringPoint', 'MeteringPoints'],
  endpoints: builder => ({
    getMeteringPoints: builder.query<
      MeteringPoint[],
      MeteringPointFilters | void
    >({
      query: (data = {}) => ({
        url: '/metering-points/query',
        method: 'POST',
        data,
      }),
      providesTags: ['MeteringPoint', 'MeteringPoints'],
    }),
    getMeteringPointById: builder.query<MeteringPoint, string>({
      query: id => ({
        url: `/metering-points/${id}`,
      }),
      providesTags: ['MeteringPoint'],
    }),
    createMeteringPoint: builder.mutation<
      MeteringPoint,
      MeteringPointRequestBody
    >({
      query: data => ({
        url: '/metering-points',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPoint',
        { type: 'MeteringPoint', id: result.id },
      ],
    }),
    updateMeteringPoint: builder.mutation<
      MeteringPoint,
      MeteringPointRequestBody
    >({
      query: data => ({
        url: `/metering-points/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPoint',
        { type: 'MeteringPoint', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringPointsQuery,
  useLazyGetMeteringPointByIdQuery,
  useGetMeteringPointByIdQuery,
  useCreateMeteringPointMutation,
  useUpdateMeteringPointMutation,
} = meteringPointApi;
