import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

const GasDistributionStationInformation = React.lazy(
  () => import('@/pages/GasDistributionStationDetails/views/Information'),
);

const GasDistributionStationGasThreads = React.lazy(
  () => import('@/pages/GasDistributionStationDetails/views/GasThreads'),
);

export const gasDistributionStationRoutes: RouteObject[] = [
  {
    element: <GasDistributionStationInformation />,
    index: true,
  },
  {
    path: `gas-threads`,
    element: <GasDistributionStationGasThreads />,
  },
];
