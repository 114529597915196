import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  TransportOrganizationFilters,
  TransportOrganizationQuery,
} from './types';

export const transportOrganizationApi = createApi({
  reducerPath: 'transportOrganizationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/transport-organizations`,
  }),
  endpoints: builder => ({
    getTransportOrganizations: builder.query<
      TransportOrganizationQuery[],
      TransportOrganizationFilters | void
    >({
      query: (data = {}) => ({
        url: '/query',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useGetTransportOrganizationsQuery } = transportOrganizationApi;
