import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const CategoryLabel = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
});

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexBasis: 'row',
  padding: '2px 8px',
  borderRadius: '4px',
  justifyContent: 'space-between',
  '&:hover': {
    background: 'rgba(255,255,255, 0.1)',
  },
  '&.active': {
    '& > div > a': {
      textDecoration: 'none',
      '&:active': {
        textDecoration: 'none',
      },
      '.title': {
        textDecoration: 'none',
        color: theme.palette.neutral[700],
      },
      '.title + .MuiSvgIcon-root': {
        fill: theme.palette.neutral[700],
      },
    },
    '.MuiSvgIcon-root': {
      fill: theme.palette.neutral[700],
    },
    background: theme.palette.neutral[200],
    '&:hover': {
      background: theme.palette.neutral[400],
    },
  },
  '.MuiSvgIcon-root': {
    width: 18,
    height: 18,
    fill: theme.palette.common.white,
  },
}));

export const TextWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '28px',
  flexDirection: 'row-reverse',
  textDecoration: 'none',
});
