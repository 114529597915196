import { FC } from 'react';

import StatusChip from '@components/ui/StatusChip';

type MeteringPointStatusId =
  | 'e80bb1b3-698c-4457-962c-df5dd7a2fb95'
  | 'c738d50f-8089-4470-94f0-c83f4574ffc2'
  | 'f80f15a3-57c6-453e-93b4-45896d3a7e7b'
  | 'ad296d15-de10-4916-b53e-16deb9d2d621';
type MeteringPointStatusTitle =
  | 'Включена'
  | 'Відключена'
  | 'Ліквідована'
  | 'Не визначено';
type MeteringPointStatus = Record<
  MeteringPointStatusId,
  {
    title: MeteringPointStatusTitle;
    color: string;
  }
>;

export const STATUS: MeteringPointStatus = {
  'e80bb1b3-698c-4457-962c-df5dd7a2fb95': {
    title: 'Включена',
    color: 'green',
  },
  'c738d50f-8089-4470-94f0-c83f4574ffc2': {
    title: 'Відключена',
    color: 'yellow',
  },
  'f80f15a3-57c6-453e-93b4-45896d3a7e7b': {
    title: 'Ліквідована',
    color: 'red',
  },
  'ad296d15-de10-4916-b53e-16deb9d2d621': {
    title: 'Не визначено',
    color: 'grey',
  },
};

type MeteringPointStatusProps = {
  statusId: MeteringPointStatusId | string;
};

export const MeteringPointStatus: FC<MeteringPointStatusProps> = ({
  statusId,
}) => {
  if (!STATUS[statusId]) {
    return null;
  }

  return <StatusChip {...STATUS[statusId]} />;
};
