import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const consumptionCategoryKindsApi = createApi({
  reducerPath: 'consumptionCategoryKindsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/consumption-category-kinds`,
  }),
  endpoints: builder => {
    return {
      getConsumptionCategoryKinds: builder.query<ReferenceBook[], void>({
        query: () => ({
          url: ``,
        }),
      }),
    };
  },
});

export const { useGetConsumptionCategoryKindsQuery } =
  consumptionCategoryKindsApi;
