import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { NodeSealTypes } from './types';

export const nodeSealTypesApi = createApi({
  reducerPath: 'nodeSealTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/seal-types`,
  }),
  endpoints: builder => ({
    getNodeSealTypes: builder.query<NodeSealTypes[], void>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetNodeSealTypesQuery } = nodeSealTypesApi;
