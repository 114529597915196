import { ReactNode, useState } from 'react';

import clsx from 'clsx';
import { NavLink, useMatch } from 'react-router-dom';

import useUi from '@/hooks/useUi';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Collapse } from '@mui/material';

import NavItem from '../NavItem';
import { CategoryLabel, TextWrapper, Wrapper } from './styled';

type NawItem = {
  icon?: ReactNode;
  title: string;
  path?: string;
  type?: 'nav';
  collapse?: boolean;
  items?: { icon?: ReactNode; title: string; path: string }[];
};

interface NavCategoryProps {
  title?: string;
  path?: string;
  items?: NawItem[];
  collapse?: boolean;
  icon?: ReactNode;
}

const NavCategory = ({
  title,
  path,
  items,
  collapse,
  icon,
}: NavCategoryProps) => {
  const [isSidebarMinimized] = useUi();
  const [isOpen, setIsOpen] = useState(!collapse);
  const match = useMatch(location.pathname);

  const hasPath = !!path;
  const isActiveRoute = hasPath && match?.pathnameBase === path;

  const labelProps = path
    ? {
        component: NavLink,
        to: path,
      }
    : {};

  return (
    <Stack
      key={title}
      sx={{
        width: '100%',
      }}
    >
      {!isSidebarMinimized && title ? (
        <Wrapper className={clsx(isActiveRoute && 'active')}>
          <CategoryLabel
            className='category-label'
            onClick={() => !hasPath && setIsOpen(prev => !prev)}
          >
            <TextWrapper {...labelProps}>
              <Typography
                className='title'
                level='body-sm'
                fontWeight={500}
                sx={{
                  padding: icon ? '4px 0 4px 4px' : '0',
                  color: theme => theme.palette.common.white,
                }}
              >
                {title}
              </Typography>
              {icon}
            </TextWrapper>

            <ChevronLeftIcon
              sx={{
                transform: isOpen ? 'rotate(-90deg)' : 'rotate(180deg)',
                transition: 'all .15s ease-in 0s',
                width: 18,
                height: 18,
              }}
              onClick={() => hasPath && setIsOpen(prev => !prev)}
            />
          </CategoryLabel>
        </Wrapper>
      ) : null}

      <Collapse in={!!isSidebarMinimized || isOpen} collapsedSize={0}>
        {items?.map(item =>
          item?.items?.length > 0 ? (
            <NavCategory key={item.title} {...item} />
          ) : (
            <NavItem key={item.title} icon={item.icon} path={item.path}>
              {item.title}
            </NavItem>
          ),
        )}
      </Collapse>
    </Stack>
  );
};

export default NavCategory;
