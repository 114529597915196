import { createApi } from '@reduxjs/toolkit/query/react';

import {
  Subdivision,
  SubdivisionBase,
  SubdivisionFilters,
  SubdivisionInput,
} from '@/features/subdivisions/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

const normalizeSubdivisionResponse = (data: Subdivision) => {
  if (data?.address?.buildingNumber === null) {
    data.address.buildingNumber = '';
  }
  if (data?.address?.officeNumber === null) {
    data.address.officeNumber = '';
  }
  if (data?.representative?.email === null) {
    data.representative.email = '';
  }
  if (data?.representative?.phoneNumber === null) {
    data.representative.phoneNumber = '';
  }
  if (data?.representative?.startDate === null) {
    data.representative.startDate = '';
  }
  if (data?.representative?.endDate === null) {
    data.representative.endDate = '';
  }
  if (data?.signatory?.email === null) {
    data.signatory.email = '';
  }
  if (data?.signatory?.phoneNumber === null) {
    data.signatory.phoneNumber = '';
  }
  if (data?.signatory?.startDate === null) {
    data.representative.startDate = '';
  }
  if (data?.signatory?.endDate === null) {
    data.representative.endDate = '';
  }
  return data;
};

export const subdivisionsApi = createApi({
  reducerPath: 'subdivisionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/structural-subdivisions`,
  }),
  tagTypes: ['Subdivisions', 'Subdivision'],
  endpoints: builder => {
    return {
      getSubdivisions: builder.query<SubdivisionBase[], SubdivisionFilters>({
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: filters,
        }),
        providesTags: ['Subdivisions'],
      }),
      getSubdivisionById: builder.query<Subdivision, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeSubdivisionResponse,
        providesTags: (_, __, id) => [{ type: 'Subdivision', id }],
      }),
      createSubdivision: builder.mutation<Subdivision, SubdivisionInput>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data,
        }),
        invalidatesTags: result => [
          'Subdivisions',
          { type: 'Subdivision', id: result.id },
        ],
      }),
      updateSubdivisionById: builder.mutation<Subdivision, SubdivisionInput>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data,
        }),
        invalidatesTags: result => [
          'Subdivisions',
          { type: 'Subdivision', id: result.id },
        ],
      }),
      deleteSubdivisionById: builder.mutation<Subdivision, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'Subdivisions',
          { type: 'Subdivision', id },
        ],
      }),
    };
  },
});

export const {
  useGetSubdivisionsQuery,
  useGetSubdivisionByIdQuery,
  useCreateSubdivisionMutation,
  useUpdateSubdivisionByIdMutation,
  useLazyGetSubdivisionsQuery,
} = subdivisionsApi;
