import { ReactNode } from 'react';

import { NavLink } from 'react-router-dom';

import useUi from '@/hooks/useUi';

import { Caption, Container, IconPlaceholder } from './styled';

interface NavItemProps {
  icon?: ReactNode;
  children: ReactNode;
  path: string;
}

const NavItem = ({ icon, children, path }: NavItemProps) => {
  const [minimized] = useUi();

  return (
    <Container
      minimized={!!minimized}
      component={NavLink}
      to={path}
      className='nav-link'
    >
      {icon || <IconPlaceholder />}
      {!minimized && <Caption>{children}</Caption>}
    </Container>
  );
};

export default NavItem;
