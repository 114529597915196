import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

const MeteringPointInformation = React.lazy(
  () => import('@/pages/MeteringPointDetails/views/Information'),
);
const MeteringPointNodes = React.lazy(
  () => import('@/pages/MeteringPointDetails/views/Nodes'),
);

// TODO: move to Pages in case if this needed
export const MeteringPointsChildrenPath = {
  METERING_POINT_ACTS: 'metering-point-acts',
  METERING_NODE: 'metering-nodes',
};

export const gasThreadMeteringPointsRoutes: RouteObject[] = [
  {
    path: ':meteringPointId',
    element: <MeteringPointInformation />,
    index: true,
  },
  {
    // TODO: acts?
    path: `:meteringPointId/${MeteringPointsChildrenPath.METERING_POINT_ACTS}`,
    element: <div>Акти по точці обліку</div>,
  },
  {
    // Вузел обліку газу  ВОГ / GasMeteringNode
    path: `:meteringPointId/${MeteringPointsChildrenPath.METERING_NODE}`,
    element: <MeteringPointNodes />,
  },
];
