import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { EquipmentType, EquipmentTypeRequestBody } from './types';

export const equipmentTypeApi = createApi({
  reducerPath: 'equipmentTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL + '/equipment-types',
  }),
  tagTypes: ['EquipmentType', 'EquipmentTypes'],
  endpoints: builder => ({
    getEquipmentTypes: builder.query<EquipmentType[], void>({
      query: () => ({
        url: '/',
      }),
      providesTags: ['EquipmentType', 'EquipmentTypes'],
    }),
    getEquipmentTypeById: builder.query<EquipmentType, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['EquipmentType'],
    }),
    createEquipmentType: builder.mutation<
      EquipmentType,
      EquipmentTypeRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentType',
        { type: 'EquipmentType', id: result.id },
      ],
    }),
    updateEquipmentType: builder.mutation<
      EquipmentType,
      EquipmentTypeRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentType',
        { type: 'EquipmentType', id: result.id },
      ],
    }),
  }),
});

export const { useGetEquipmentTypesQuery } = equipmentTypeApi;
