import { createApi } from '@reduxjs/toolkit/query/react';

import {
  GasRoute,
  GasRouteFilters,
  GasRouteRequestBody,
} from '@/features/gasRoutes/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const gasRoutesApi = createApi({
  reducerPath: 'gasRoutesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/gas-routes`,
  }),
  tagTypes: ['GasRoutes', 'GasRoute'],
  endpoints: builder => ({
    getGasRoutes: builder.query<GasRoute[], GasRouteFilters | void>({
      query: (data = {}) => ({
        url: `/query`,
        method: 'POST',
        data,
      }),
      providesTags: ['GasRoutes'],
    }),
    getGasRouteById: builder.query<GasRoute, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'GasRoute', id }],
    }),
    createGasRoute: builder.mutation<GasRoute, GasRouteRequestBody>({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasRoutes',
        { type: 'GasRoute', id: result.id },
      ],
    }),
    updateGasRoute: builder.mutation<GasRoute, GasRouteRequestBody>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasRoutes',
        { type: 'GasRoute', id: result.id },
      ],
    }),
    deleteGasRoute: builder.mutation<GasRoute, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'GasRoutes',
        { type: 'GasRoute', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetGasRoutesQuery,
  useGetGasRouteByIdQuery,
  useCreateGasRouteMutation,
  useUpdateGasRouteMutation,
  useDeleteGasRouteMutation,
} = gasRoutesApi;
