import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringNodeLocationType } from './types';

export const meteringNodeLocationTypeApi = createApi({
  reducerPath: 'meteringNodeLocationTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-node-locations`,
  }),
  endpoints: builder => ({
    getMeteringNodeLocationType: builder.query<MeteringNodeLocationType[], any>(
      {
        query: () => ({
          url: '/',
        }),
      },
    ),
  }),
});

export const { useGetMeteringNodeLocationTypeQuery } =
  meteringNodeLocationTypeApi;
