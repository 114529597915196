import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasDistributionStation,
  GasDistributionStationFilters,
  GasDistributionStationRequestBody,
} from './types';

export const gasDistributionStationsApi = createApi({
  reducerPath: 'stationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  tagTypes: ['GasDistributionStation', 'GasDistributionStations'],
  endpoints: builder => ({
    getGasDistributionStations: builder.query<
      GasDistributionStation[],
      GasDistributionStationFilters
    >({
      query: data => ({
        url: '/gas-distribution-stations/query',
        method: 'POST',
        data,
      }),
      providesTags: ['GasDistributionStations', 'GasDistributionStation'],
    }),
    createGasDistributionStations: builder.mutation<
      GasDistributionStation,
      GasDistributionStationRequestBody
    >({
      query: data => ({
        url: '/gas-distribution-stations',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasDistributionStation',
        { type: 'GasDistributionStation', id: result.id },
      ],
    }),
    updateGasDistributionStations: builder.mutation<
      GasDistributionStation,
      GasDistributionStationRequestBody
    >({
      query: data => ({
        url: `/gas-distribution-stations/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasDistributionStation',
        { type: 'GasDistributionStation', id: result.id },
      ],
    }),
    getGasDistributionStationById: builder.query<
      GasDistributionStation,
      string
    >({
      query: id => ({
        url: `/gas-distribution-stations/${id}`,
      }),
      providesTags: ['GasDistributionStations', 'GasDistributionStation'],
    }),
  }),
});

export const {
  useGetGasDistributionStationsQuery,
  useLazyGetGasDistributionStationByIdQuery,
  useGetGasDistributionStationByIdQuery,
  useUpdateGasDistributionStationsMutation,
  useCreateGasDistributionStationsMutation,
} = gasDistributionStationsApi;
