import useUi from '@/hooks/useUi';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';

const SidebarToggleButton = () => {
  const [minimized, toggle] = useUi();
  return (
    <IconButton
      aria-label='toggle-sidebar'
      onClick={() => typeof toggle === 'function' && toggle()}
      sx={{
        position: 'absolute',
        top: '45px',
        right: '-7px',
        backgroundColor: theme => theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme => theme.palette.common.white,
        },
        padding: 0.5,
        boxShadow: 1,
        zIndex: 1200,
      }}
    >
      <ChevronLeftIcon
        sx={{
          width: 14,
          height: 14,
          transform: minimized ? 'rotate(180deg)' : 'none',
        }}
      />
    </IconButton>
  );
};

export default SidebarToggleButton;
