import Pages from '@/pages';

import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';

import ActIcon from '@assets/SAct.svg?react';
import BridgeIcon from '@assets/SBridge.svg?react';
import CommercialMeteringPointIcon from '@assets/SCommercialMeteringPoint.svg?react';
import ContractsIcon from '@assets/SContracts.svg?react';
import CounterpartsIcon from '@assets/SCounterparts.svg?react';
import GRSIcon from '@assets/SGRS.svg?react';
import GRSMeteringPoint from '@assets/SGRSMeteringPoint.svg?react';
import SGRSTread from '@assets/SGRSThread.svg?react';
import OrgIcon from '@assets/SOrg.svg?react';
import RoutesIcon from '@assets/SRoute.svg?react';
import SectorIcon from '@assets/SSector.svg?react';

import { Nav } from './types';

const navigationConfig: Nav = [
  {
    type: 'nav',
    collapse: false,
    items: [
      {
        icon: <OrgIcon />,
        title: 'Мої організації',
        path: '/',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'nav',
    title: 'Комерційні споживачі',
    collapse: true,
    items: [
      {
        icon: <CounterpartsIcon />,
        title: 'Контрагенти',
        path: '/counterparties',
      },
      {
        icon: <ContractsIcon />,
        title: 'Договори',
        path: '/contracts',
      },
      {
        icon: <CommercialMeteringPointIcon />,
        title: 'Комерційні точки обліку',
        path: '/commercial-metering-points',
      },
    ],
  },
  { type: 'divider', show: isMinimized => isMinimized },
  {
    type: 'nav',
    title: 'ГРС',
    collapse: true,
    items: [
      {
        icon: <GRSIcon />,
        title: 'ГРС',
        path: '/gas-distribution-stations',
      },
      {
        icon: <SGRSTread />,
        title: 'Нитки ГРС',
        path: '/gas-threads',
      },
      {
        title: 'Точки обліку ГРС',
        path: '/metering-points',
        icon: <GRSMeteringPoint />,
      },
      {
        icon: <SectorIcon />,
        title: 'Сектори',
        path: Pages.SECTORS,
      },
      {
        icon: <BridgeIcon />,
        title: 'Перемички',
        path: Pages.SECTOR_BRIDGES,
      },
      {
        icon: <ActIcon />,
        title: 'Акти по перемичкам',
        path: Pages.BRIDGE_ACTS,
      },
      {
        icon: <RoutesIcon />,
        title: 'Маршрути',
        path: Pages.GAS_ROUTES,
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'nav',
    collapse: false,
    items: [
      { icon: <TextSnippetOutlined />, title: 'Опитування модемів', path: '/' },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Оперативний облік',
        path: '/counterparts',
      },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Фактичний облік',
        path: '/counterparts',
      },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Аналітика',
        path: '/counterparts',
      },
    ],
  },
];

export default navigationConfig;
