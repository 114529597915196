import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const gasRouteTypesApi = createApi({
  reducerPath: 'gasRouteTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/gas-route-types`,
  }),
  endpoints: builder => ({
    getGasRouteTypes: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: `/`,
      }),
    }),
  }),
});

export const { useGetGasRouteTypesQuery } = gasRouteTypesApi;
