import { GridRowId } from '@mui/x-data-grid';

const enum Pages {
  ROOT = '/',
  ONBOARDING = '/onboarding',
  CHANGE_PASSWORD = '/profile/change-password',
  USERS = '/users',
  USER_GROUPS = '/user-groups',
  SECTOR_BRIDGES = '/sector-bridges',
  BRIDGE_ACTS = '/bridge-acts',
  GAS_ROUTES = '/gas-routes',

  // NOT IN THE ROUTER
  ORGANIZATIONS_PART = `organizations`,
  MAIN_ORGANIZATION_PART = `main-organization`,
  EMPLOYEES_PART = `employees`,
  SUBDIVISIONS_PART = `subdivisions`,
  GAS_DISTRIBUTION_STATIONS = 'gas-distribution-stations',
  ROLES_PART = 'roles',
  GAS_THREADS = 'gas-threads',
  METERING_POINTS = 'metering-points',
  METERING_NODES = 'metering-nodes',
  SECTORS = 'sectors',
  ALLOWED_ROUTES = 'allowed-routes',
  COUNTERPARTIES = '/counterparties',
  COMMERCIAL_METERING_POINTS_PART = 'commercial-metering-points',
  OPERATING_GAS_ROUTES_PART = 'active',
  CREATE_PART = 'create',

  CONTRACTS = 'contracts',
  SEALS = 'seals',
  DEVICES = 'devices',
  EQUIPMENT = 'equipment',
}

export const getUserURL = (id: string | GridRowId) => `${Pages.USERS}/${id}`;

export const getOrganizationURL = (id: string | GridRowId) =>
  `/${Pages.ORGANIZATIONS_PART}/${id}`;

export const getMainOrganizationURL = (id: string | GridRowId) =>
  `/${Pages.MAIN_ORGANIZATION_PART}/${id}`;

export const getSubdivisionURL = (
  orgId: string | GridRowId,
  id: string | GridRowId,
) => `${getOrganizationURL(orgId)}/${Pages.SUBDIVISIONS_PART}/${id}`;

interface GetEmployeesURLProps {
  orgId: string | GridRowId;
  subId?: string | GridRowId;
}

interface GetAllowedRoutesURLProps {
  sectorId?: string | GridRowId;
}

export const getEmployeesURL = ({ orgId, subId }: GetEmployeesURLProps) => {
  let part = '';
  if (subId) {
    part = `${Pages.SUBDIVISIONS_PART}/${subId}/`;
  }
  return `${getOrganizationURL(orgId)}/${part}${Pages.EMPLOYEES_PART}`;
};

export const getEmployeeURL = ({
  orgId,
  subId,
  id,
}: GetEmployeesURLProps & {
  id: string | GridRowId;
}) => `${getEmployeesURL({ orgId, subId })}/${id}`;

export const getOrganizationRolesURL = (id: string | GridRowId) =>
  `${getOrganizationURL(id)}/${Pages.ROLES_PART}`;

export const getMainOrganizationRolesURL = (id: string | GridRowId) =>
  `${getMainOrganizationURL(id)}/${Pages.ROLES_PART}`;

export const getSubdivisionRolesURL = (
  orgId: string | GridRowId,
  id: string | GridRowId,
) => `${getSubdivisionURL(orgId, id)}/${Pages.ROLES_PART}`;

export const getUserGroupsURL = (id: string | GridRowId) =>
  `${Pages.USER_GROUPS}/${id}`;

export const getAllowedRoutesURL = ({
  sectorId,
  id,
}: GetAllowedRoutesURLProps & {
  id: string | GridRowId;
}) => `/${Pages.SECTORS}/${sectorId}/${Pages.ALLOWED_ROUTES}/${id}`;

export const getSectorBridgeURL = (id: string | GridRowId) =>
  `${Pages.SECTOR_BRIDGES}/${id}`;

export const getCounterpartURL = (counterpartId: string | GridRowId) =>
  `${Pages.COUNTERPARTIES}/${counterpartId}`;

export const getGasRouteURL = (id: string | GridRowId) =>
  `${Pages.GAS_ROUTES}/${id}`;

export const getOperatingGasRoutesURL = () =>
  `${Pages.GAS_ROUTES}/${Pages.OPERATING_GAS_ROUTES_PART}`;

export const getOperatingGasRouteURL = (id: string | GridRowId) =>
  `${getOperatingGasRoutesURL()}/${id}`;

export const getOperatingGasRouteCreateURL = () =>
  `${getOperatingGasRoutesURL()}/${Pages.CREATE_PART}`;

export const getMeteringPointNodesURL = (meteringPointId: string | GridRowId) =>
  `${Pages.METERING_POINTS}/${meteringPointId}/${Pages.METERING_NODES}`;

export const getMeteringNodeURL = (
  meteringNodeId: string | GridRowId,
  meteringPointId: string | GridRowId,
) => `/${getMeteringPointNodesURL(meteringPointId)}/${meteringNodeId}`;

export const getMeteringSealsURL = (meteringPointId: string | GridRowId) =>
  `/${Pages.METERING_POINTS}/${meteringPointId}/${Pages.SEALS}`;

export const getMeteringSealURL = (
  meteringPointId: string | GridRowId,
  id: string | GridRowId,
) => `${getMeteringSealsURL(meteringPointId)}/${id}`;

export const getMeteringModemURL = (
  meteringPointId: string | GridRowId,
  id: string | GridRowId,
) => `/${Pages.METERING_POINTS}/${meteringPointId}/${Pages.DEVICES}/${id}`;

export default Pages;
