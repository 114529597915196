import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringModemType } from './types';

export const meteringModemTypeApi = createApi({
  reducerPath: 'meteringModemTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/modem-types`,
  }),
  endpoints: builder => ({
    getMeteringModemType: builder.query<MeteringModemType[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const { useGetMeteringModemTypeQuery } = meteringModemTypeApi;
