import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasConsumingEquipment,
  GasConsumingEquipmentFilter,
  GasConsumingEquipmentQuery,
  GasConsumingEquipmentRequestBody,
} from './types';

export const gasConsumingEquipmentApi = createApi({
  reducerPath: 'gasConsumingEquipmentApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL + '/gas-consuming-equipment',
  }),
  tagTypes: ['GasConsumingEquipment', 'GasConsumingEquipments'],
  endpoints: builder => ({
    getGasConsumingEquipments: builder.query<
      GasConsumingEquipmentQuery[],
      GasConsumingEquipmentFilter
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: ['GasConsumingEquipment', 'GasConsumingEquipments'],
    }),
    createGasConsumingEquipments: builder.mutation<
      GasConsumingEquipment,
      GasConsumingEquipmentRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasConsumingEquipment',
        { type: 'GasConsumingEquipment', id: result.id },
      ],
    }),
    updateGasConsumingEquipments: builder.mutation<
      GasConsumingEquipment,
      GasConsumingEquipmentRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasConsumingEquipment',
        { type: 'GasConsumingEquipment', id: result.id },
      ],
    }),
    getGasConsumingEquipmentsById: builder.query<GasConsumingEquipment, string>(
      {
        query: id => ({
          url: `/${id}`,
        }),
        providesTags: ['GasConsumingEquipment', 'GasConsumingEquipments'],
      },
    ),
  }),
});

export const {
  useGetGasConsumingEquipmentsQuery,
  useGetGasConsumingEquipmentsByIdQuery,
  useCreateGasConsumingEquipmentsMutation,
  useUpdateGasConsumingEquipmentsMutation,
} = gasConsumingEquipmentApi;
