import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { Sector, SectorFilters, SectorRequestPayload } from './types';

export const sectorsApi = createApi({
  reducerPath: 'sectorsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/sectors`,
  }),
  tagTypes: ['Sectors', 'Sector'],
  endpoints: builder => ({
    getSectors: builder.query<Sector[], SectorFilters | void>({
      query: (filters = {}) => ({
        url: '/query',
        method: 'POST',
        data: filters,
      }),
      providesTags: ['Sectors'],
    }),
    getSectorById: builder.query<Sector, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: result => [{ type: 'Sector', id: result.id }],
    }),
    createSector: builder.mutation<Sector, SectorRequestPayload>({
      query: data => ({
        url: '',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => ['Sectors', { type: 'Sector', id: result.id }],
    }),
    updateSector: builder.mutation<Sector, SectorRequestPayload>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => ['Sectors', { type: 'Sector', id: result.id }],
    }),
  }),
});

export const {
  useGetSectorsQuery,
  useGetSectorByIdQuery,
  useCreateSectorMutation,
  useUpdateSectorMutation,
} = sectorsApi;
