import Pages from '@/pages';

import User from '@assets/admin-user.svg?react';
import OtherBook from '@assets/other-book.svg?react';
import TeamGroup from '@assets/team-group.svg?react';

const navigationConfig = {
  title: 'Адміністрування',
  collapse: true,
  items: [
    { icon: <User />, title: 'Користувачі', path: Pages.USERS },
    {
      icon: <TeamGroup />,
      title: 'Групи користувачів',
      path: Pages.USER_GROUPS,
    },
    {
      icon: <OtherBook />,
      title: 'Довідники',
      path: '/counterparts',
    },
  ],
};

export default navigationConfig;
