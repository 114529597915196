import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

const CommercialMeteringPointInformation = React.lazy(
  () => import('@/pages/CommercialMeteringPointDetails/views/Information'),
);
const CommercialMeteringPointEquipment = React.lazy(
  () =>
    import(
      '@/pages/CommercialMeteringPointDetails/views/GasConsumingEquipment'
    ),
);
const CommercialMeteringPointDevices = React.lazy(
  () => import('@/pages/CommercialMeteringPointDetails/views/Devices'),
);

export const commercialMeteringPointDetailsRoutes: RouteObject[] = [
  {
    element: <CommercialMeteringPointInformation />,
    index: true,
  },
  {
    path: `${Pages.EQUIPMENT}`,
    element: <CommercialMeteringPointEquipment />,
  },
  {
    path: `${Pages.DEVICES}`,
    element: <CommercialMeteringPointDevices />,
  },
];
