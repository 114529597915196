import { FC, useState } from 'react';

import ErrorRounded from '@mui/icons-material/ErrorRounded';
import InfoRounded from '@mui/icons-material/InfoRounded';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';

export type NotificationType = Exclude<
  DefaultColorPalette,
  'primary' | 'neutral'
>;

const NotificationIcons: Record<NotificationType, typeof ErrorRounded> = {
  danger: ErrorRounded,
  success: PlaylistAddCheckCircleRoundedIcon,
  warning: InfoRounded,
};

type NotificationProps = {
  autoHideDuration: number;
  type: NotificationType;
  message: string;
  dismissTitle?: string;
  show?: boolean;
};

export const Notification: FC<NotificationProps> = ({
  autoHideDuration = 3000,
  type,
  message,
  dismissTitle = 'Закрити',
  show = true,
}) => {
  const [open, setOpen] = useState(show);
  const Icon = NotificationIcons[type];

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      variant='soft'
      color={type}
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      startDecorator={<Icon />}
      endDecorator={
        <Button
          onClick={() => setOpen(false)}
          size='sm'
          variant='soft'
          color={type}
        >
          {dismissTitle}
        </Button>
      }
    >
      {message}
    </Snackbar>
  );
};
