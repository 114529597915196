import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

const SectorDetailsInformation = React.lazy(
  () => import('@/pages/SectorDetails/views/Information'),
);

const SectorDetailsAllowedRoutes = React.lazy(
  () => import('@/pages/SectorDetails/views/AllowedRoutes'),
);

const SectorAllowedRoutesDetails = React.lazy(
  () => import('@/forms/GasRouteForm'),
);

export const SectorDetailsRoutes = {
  ALLOWED_ROUTES: 'allowed-routes',
};

export const sectorDetailsRoutes: RouteObject[] = [
  {
    element: <SectorDetailsInformation />,
    index: true,
  },
  {
    path: SectorDetailsRoutes.ALLOWED_ROUTES,
    element: <SectorDetailsAllowedRoutes />,
    children: [
      {
        path: `:gasRouteId`,
        element: <SectorAllowedRoutesDetails />,
      },
    ],
  },
];
