import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authApi } from '@/features/auth/authApi';
import authSlice from '@/features/auth/authSlice';
import { bridgeActsApi } from '@/features/bridgeActs/bridgeActsApi';
import { commercialMeteringPointsApi } from '@/features/commercialMeteringPoint/commercialMeteringPointsApi';
import { consumptionCategoryKindsApi } from '@/features/consumptionCategoryKinds/consumptionCategoryKindsApi';
import { contractsApi } from '@/features/contracts/contractsApi';
import { counterpartiesApi } from '@/features/counterparties/counterparties';
import { economicSectorObjectTypesApi } from '@/features/economicSectorObjectTypes/economicSectorObjectTypesApi';
import { employeeStatusesApi } from '@/features/employees/employeeStatusesApi';
import { employeesApi } from '@/features/employees/employeesApi';
import { equipmentGroupApi } from '@/features/equipmentGroup/equipmentGroupApi';
import { equipmentManufacturerApi } from '@/features/equipmentManufacturer/equipmentManufacturerApi';
import { equipmentTypeApi } from '@/features/equipmentType/equipmentTypeApi';
import { gasConsumingEquipmentApi } from '@/features/gasConsumingEquipment/gasConsumingEquipmentApi';
import { gasDistributionStationCategoryApi } from '@/features/gasDistributionStationCategory';
import { gasDistributionStationTypeApi } from '@/features/gasDistributionStationType';
import { gasDistributionStationsApi } from '@/features/gasDistributionStations/gasDistributionStationsApi';
import { gasRouteTypesApi } from '@/features/gasRouteTypes/gasRouteTypesApi';
import { gasRoutesApi } from '@/features/gasRoutes/gasRoutesApi';
import { gasThreadApi } from '@/features/gasThread/gasThreadApi';
import { locationsApi } from '@/features/locations/locationsApi';
import { mainOrganizationApi } from '@/features/mainOrganization/mainOrganizationApi';
import { measurementPeriodsApi } from '@/features/measurementPeriods/measurementPeriodsApi';
import { meteringDevicesApi } from '@/features/meteringDevices/meteringDevicesApi';
import { meteringModemApi } from '@/features/meteringModem/meteringModemApi';
import { meteringModemTypeApi } from '@/features/meteringModemType/meteringModemTypeApi';
import { meteringNodeApi } from '@/features/meteringNode/meteringNodeApi';
import { meteringNodeKindApi } from '@/features/meteringNodeKind/meteringNodeKindApi';
import { meteringNodeLocationTypeApi } from '@/features/meteringNodeLocationType/meteringNodeLocationTypeApi';
import { meteringNodeTypeApi } from '@/features/meteringNodeType/meteringNodeTypeApi';
import { meteringPointApi } from '@/features/meteringPoint/meteringPointApi';
import { meteringPointStatusesApi } from '@/features/meteringPointStatuses/meteringPointStatusesApi';
import { meteringPointTypeApi } from '@/features/meteringPointType/meteringPointApi';
import { meteringSealApi } from '@/features/meteringSeal/meteringSealApi';
import { networkTypeApi } from '@/features/networkType/networkTypeApi';
import { nodeSealLocationApi } from '@/features/nodeSealLocation/nodeSealLocationApi';
import { nodeSealTypesApi } from '@/features/nodeSealTypes/nodeSealTypesApi';
import { operatingGasRoutesApi } from '@/features/operatingGasRoutes/operatingGasRoutesApi';
import { organizationsApi } from '@/features/organizations/organizationsApi';
import { ownDemandTypeApi } from '@/features/ownDemandType/ownDemandTypeApi';
import { pressureCategoriesApi } from '@/features/pressureCategories/pressureCategoriesApi';
import { rolesApi } from '@/features/roles/rolesApi';
import { seasonTypesApi } from '@/features/seasonTypes/seasonTypesApi';
import { sectorBridgeActionsApi } from '@/features/sectorBridgeActions/sectorBridgeActionsApi';
import { sectorBridgeStatusesApi } from '@/features/sectorBridgeStatuses/sectorBridgeStatusesApi';
import { sectorBridgesApi } from '@/features/sectorBridges/sectorBridgesApi';
import { sectorsApi } from '@/features/sectors/sectorsApi';
import { serviceOrganizationsApi } from '@/features/serviceOrganizations/serviceOrganizationsApi';
import { subdivisionsApi } from '@/features/subdivisions/subdivisionsApi';
import { transportOrganizationApi } from '@/features/transportOrganization/transportOrganizationApi';
import uiSlice from '@/features/ui/uiSlice';
import { usersApi } from '@/features/users/usersApi';
import usersSlice from '@/features/users/usersSlice';

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [subdivisionsApi.reducerPath]: subdivisionsApi.reducer,
  [employeeStatusesApi.reducerPath]: employeeStatusesApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [counterpartiesApi.reducerPath]: counterpartiesApi.reducer,
  [meteringNodeApi.reducerPath]: meteringNodeApi.reducer,
  [meteringNodeTypeApi.reducerPath]: meteringNodeTypeApi.reducer,
  [meteringNodeLocationTypeApi.reducerPath]:
    meteringNodeLocationTypeApi.reducer,
  [seasonTypesApi.reducerPath]: seasonTypesApi.reducer,
  [meteringNodeKindApi.reducerPath]: meteringNodeKindApi.reducer,
  [commercialMeteringPointsApi.reducerPath]:
    commercialMeteringPointsApi.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [gasDistributionStationsApi.reducerPath]: gasDistributionStationsApi.reducer,
  [gasDistributionStationTypeApi.reducerPath]:
    gasDistributionStationTypeApi.reducer,
  [gasDistributionStationCategoryApi.reducerPath]:
    gasDistributionStationCategoryApi.reducer,
  [gasThreadApi.reducerPath]: gasThreadApi.reducer,
  [networkTypeApi.reducerPath]: networkTypeApi.reducer,
  [meteringPointApi.reducerPath]: meteringPointApi.reducer,
  [meteringPointTypeApi.reducerPath]: meteringPointTypeApi.reducer,
  [sectorsApi.reducerPath]: sectorsApi.reducer,
  [sectorBridgesApi.reducerPath]: sectorBridgesApi.reducer,
  [sectorBridgeActionsApi.reducerPath]: sectorBridgeActionsApi.reducer,
  [sectorBridgeStatusesApi.reducerPath]: sectorBridgeStatusesApi.reducer,
  [bridgeActsApi.reducerPath]: bridgeActsApi.reducer,
  [ownDemandTypeApi.reducerPath]: ownDemandTypeApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [gasRoutesApi.reducerPath]: gasRoutesApi.reducer,
  [gasRouteTypesApi.reducerPath]: gasRouteTypesApi.reducer,
  [operatingGasRoutesApi.reducerPath]: operatingGasRoutesApi.reducer,
  [transportOrganizationApi.reducerPath]: transportOrganizationApi.reducer,
  [mainOrganizationApi.reducerPath]: mainOrganizationApi.reducer,
  [meteringSealApi.reducerPath]: meteringSealApi.reducer,
  [nodeSealTypesApi.reducerPath]: nodeSealTypesApi.reducer,
  [nodeSealLocationApi.reducerPath]: nodeSealLocationApi.reducer,
  [meteringDevicesApi.reducerPath]: meteringDevicesApi.reducer,
  [meteringModemApi.reducerPath]: meteringModemApi.reducer,
  [meteringModemTypeApi.reducerPath]: meteringModemTypeApi.reducer,
  [consumptionCategoryKindsApi.reducerPath]:
    consumptionCategoryKindsApi.reducer,
  [economicSectorObjectTypesApi.reducerPath]:
    economicSectorObjectTypesApi.reducer,
  [measurementPeriodsApi.reducerPath]: measurementPeriodsApi.reducer,
  [pressureCategoriesApi.reducerPath]: pressureCategoriesApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [gasConsumingEquipmentApi.reducerPath]: gasConsumingEquipmentApi.reducer,
  [equipmentGroupApi.reducerPath]: equipmentGroupApi.reducer,
  [equipmentManufacturerApi.reducerPath]: equipmentManufacturerApi.reducer,
  [equipmentTypeApi.reducerPath]: equipmentTypeApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [serviceOrganizationsApi.reducerPath]: serviceOrganizationsApi.reducer,
  [meteringPointStatusesApi.reducerPath]: meteringPointStatusesApi.reducer,
});

const middlewares = [
  authApi.middleware,
  organizationsApi.middleware,
  subdivisionsApi.middleware,
  usersApi.middleware,
  gasDistributionStationsApi.middleware,
  gasDistributionStationTypeApi.middleware,
  gasDistributionStationCategoryApi.middleware,
  gasThreadApi.middleware,
  networkTypeApi.middleware,
  meteringPointApi.middleware,
  meteringPointTypeApi.middleware,
  sectorsApi.middleware,
  sectorBridgesApi.middleware,
  bridgeActsApi.middleware,
  sectorBridgeActionsApi.middleware,
  sectorBridgeStatusesApi.middleware,
  ownDemandTypeApi.middleware,
  locationsApi.middleware,
  employeesApi.middleware,
  employeeStatusesApi.middleware,
  counterpartiesApi.middleware,
  meteringNodeApi.middleware,
  gasRoutesApi.middleware,
  gasRouteTypesApi.middleware,
  operatingGasRoutesApi.middleware,
  transportOrganizationApi.middleware,
  seasonTypesApi.middleware,
  meteringNodeKindApi.middleware,
  meteringNodeTypeApi.middleware,
  meteringNodeLocationTypeApi.middleware,
  rolesApi.middleware,
  mainOrganizationApi.middleware,
  meteringSealApi.middleware,
  nodeSealTypesApi.middleware,
  nodeSealLocationApi.middleware,
  meteringDevicesApi.middleware,
  meteringModemApi.middleware,
  meteringModemTypeApi.middleware,
  commercialMeteringPointsApi.middleware,
  consumptionCategoryKindsApi.middleware,
  economicSectorObjectTypesApi.middleware,
  measurementPeriodsApi.middleware,
  pressureCategoriesApi.middleware,
  serviceOrganizationsApi.middleware,
  gasConsumingEquipmentApi.middleware,
  equipmentGroupApi.middleware,
  equipmentManufacturerApi.middleware,
  equipmentTypeApi.middleware,
  contractsApi.middleware,
  meteringPointStatusesApi.middleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middlewares),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
