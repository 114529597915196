import { useAuth as useOIDCAuth } from 'react-oidc-context';

import { useGetUserByIdQuery } from '@/features/auth/authApi';

const KEY = `oidc.user:${import.meta.env.VITE_AUTHORITY}:${import.meta.env.VITE_CLIENT_ID}`;

export const getSessionPayload = () => {
  try {
    return JSON.parse(sessionStorage.getItem(KEY));
  } catch (err) {
    console.error(err);
    return null;
  }
};

const useAuth = () => {
  const oidc = useOIDCAuth();
  const { user: session, signoutRedirect } = oidc;

  const userId = session?.profile?.sub;

  const { data, error } = useGetUserByIdQuery(userId, {
    skip: !userId,
  });

  if (error) {
    signoutRedirect();
  }

  return { oidc, session, user: data, logout: () => signoutRedirect() };
};

export default useAuth;
