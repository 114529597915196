import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const sectorBridgeStatusesApi = createApi({
  reducerPath: 'sectorBridgeStatusesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/sector-bridge-statuses`,
  }),
  tagTypes: ['sectorBridgeStatuses', 'sectorBridgeStatus'],
  endpoints: builder => ({
    getSectorBridgeStatuses: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: ['sectorBridgeStatuses'],
    }),
  }),
});

export const { useGetSectorBridgeStatusesQuery } = sectorBridgeStatusesApi;
