import { ReactNode, useEffect } from 'react';

import { AuthProvider, useAuth as useOIDCAuth } from 'react-oidc-context';

import useAuth from '@/hooks/useAuth';

const AuthCheck = () => {
  const auth = useOIDCAuth();

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.events]);

  if (auth.isLoading) {
    return <div>Authorizing...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
  }

  return null;
};

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { oidc: auth } = useAuth();

  if (auth.isAuthenticated) {
    return children;
  }

  return null;
};

const Provider = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider
      authority={import.meta.env.VITE_AUTHORITY}
      client_id={import.meta.env.VITE_CLIENT_ID}
      redirect_uri={import.meta.env.VITE_REDIRECT_URI}
      post_logout_redirect_uri={import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI}
      scope={import.meta.env.VITE_SCOPE}
      onSigninCallback={() => window.location.replace('/')}
    >
      <AuthCheck />
      <PrivateRoute>{children}</PrivateRoute>
    </AuthProvider>
  );
};

export default Provider;
