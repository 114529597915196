import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringNodeType } from './types';

export const meteringNodeTypeApi = createApi({
  reducerPath: 'meteringNodeTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-node-types`,
  }),
  endpoints: builder => ({
    getMeteringNodeType: builder.query<MeteringNodeType[], any>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetMeteringNodeTypeQuery } = meteringNodeTypeApi;
