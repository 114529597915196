import { createApi } from '@reduxjs/toolkit/query/react';

import {
  Permission,
  Role,
  RoleCreatePayload,
  RoleType,
  RoleUpdatePayload,
  RootRoleId,
} from '@/features/roles/types';

export const getRoleType = (roleId: string) => {
  switch (roleId) {
    case RootRoleId.Administrator:
      return RoleType.Administrator;
    case RootRoleId.Manager:
      return RoleType.Manager;
    case RootRoleId.User:
      return RoleType.User;
    default:
      return RoleType.Custom;
  }
};

export const getRoleLabel = (roleId: string) => {
  if (roleId === RootRoleId.Administrator) {
    return 'Administrator';
  } else if (roleId === RootRoleId.Manager) {
    return 'Manager';
  } else if (roleId === RootRoleId.User) {
    return 'User';
  } else {
    return '';
  }
};

export const isRootRole = (roleId?: string) =>
  Object.values<string>(RootRoleId).includes(roleId);

export const extendWithRoles = (api: ReturnType<typeof createApi>): any => {
  return api
    .enhanceEndpoints({
      addTagTypes: ['Roles', 'Role'],
    })
    .injectEndpoints({
      endpoints: builder => ({
        getOrganizationRolesSystem: builder.query<Role[], string>({
          query: id => ({
            url: `/${id}/roles/system`,
          }),
        }),
        getOrganizationRoles: builder.query<Role[], string>({
          query: id => ({
            url: `/${id}/roles/query`,
          }),
          providesTags: ['Roles'],
        }),
        getOrganizationRoleById: builder.query<
          Role,
          { id: string; roleId: string }
        >({
          query: ({ id, roleId }) => ({
            url: `/${id}/roles/${roleId}`,
          }),
          providesTags: (result: Role) => [{ type: 'Role', id: result.id }],
        }),
        createOrganizationRole: builder.mutation<Role, RoleCreatePayload>({
          query: data => ({
            url: `/${data.organizationId}/roles`,
            method: 'POST',
            data,
          }),
          invalidatesTags: result => ['Roles', { type: 'Role', id: result.id }],
        }),
        updateOrganizationRoleById: builder.mutation<Role, RoleUpdatePayload>({
          query: data => ({
            url: `/${data.organizationId}/roles/${data.id}`,
            method: 'PUT',
            data,
          }),
          invalidatesTags: result => ['Roles', { type: 'Role', id: result.id }],
        }),
        getOrganizationPermissionsAvailable: builder.query<Permission, string>({
          query: id => ({
            url: `/${id}/permissions/available`,
          }),
        }),
      }),
    });
};
