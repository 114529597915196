import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const sectorBridgeActionsApi = createApi({
  reducerPath: 'sectorBridgeActionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/sector-bridge-actions`,
  }),
  tagTypes: ['sectorBridgeActions', 'sectorBridgeAction'],
  endpoints: builder => ({
    getSectorBridgeActions: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: ['sectorBridgeAction'],
    }),
  }),
});

export const { useGetSectorBridgeActionsQuery } = sectorBridgeActionsApi;
