import { Suspense } from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AuthProvider from '@/providers/AuthProvider';
import MaterialProvider from '@/providers/MaterialProvider';

import { NotificationProvider } from '@components/Notification/NotificationProvider';

import store from './app/store';
import { getRoutes } from './route';

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <MaterialProvider>
      <AuthProvider>
        <Suspense>
          <NotificationProvider>
            <RouterProvider router={getRoutes()} />
          </NotificationProvider>
        </Suspense>
      </AuthProvider>
    </MaterialProvider>
  </Provider>,
);
